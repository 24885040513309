<template>
    <div class="education-wrapper">
        <div class="education-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/admin/index' }">教务管理</el-breadcrumb-item>
                <el-breadcrumb-item>学校管理</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="btn-blue" @click="addSchool">创建学校</el-button>
        </div>
        <el-table :data="schoolList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column prop="name" align="center" label="学校名称"></el-table-column>
            <el-table-column align="center" label="LOGO">
                <template slot-scope="scope">
                    <div class="school-logo">
                        <img :src="scope.row.logo ? scope.row.logo : require('../../../assets/images/user_img.png')"
                             alt="">
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="create_time" align="center" label="创建时间"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" type="link-blue" @click="assignBtn(scope.row)">分配竞赛模板</el-link>
                    <el-link :underline="false" type="link-blue" @click="assignTheory(scope.row)">分配理论模板</el-link>
                    <el-link :underline="false" class="link-blue" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link :underline="false" type="danger" @click="deleteBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="schoolPages.currentPageNum"
                       :page-size="schoolPages.eachPageNum"
                       :total="schoolPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="schoolCurrentChange">
        </el-pagination>

        <!--        添加学校编辑学校 弹窗-->
        <el-dialog :title="schoolOperTitle" :visible.sync="addSchoolDialog" width="500px" custom-class="dialog-blue"
                   @close="resetForm()" @opened="openAddSchool" :close-on-click-modal="false" :destroy-on-close="true">
            <el-form :model="addSchoolForm" ref="addSchoolForm" :rules="schoolRules" label-position="right"
                     label-width="100px">
                <el-form-item label="学校名称" prop="name">
                    <el-input v-model="addSchoolForm.name" ref="schoolInput" autocomplete="off"
                              placeholder="4-30字"></el-input>
                </el-form-item>
                <el-form-item label="LOGO"  style="margin-bottom: 0">
                    <el-upload class="logo-uploader"
                               :action="uploadCover"
                               :headers="headersParams"
                               name="file"
                               :show-file-list="false"
                               :on-success="handleCoverSuccess"
                               :before-upload="beforeCoverUpload">
                        <img v-if="imgSrc" :src="imgSrc" class="logo-cover">
                        <i v-else class="el-icon-plus logo-uploader-icon"></i>
                    </el-upload>
                    <!--                    <span class="school-prompt">建议尺寸：200*200；格式为.jpg 或 .jpeg 或 .png</span>-->
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button size="medium" class="btn-blue" @click="addToForm('addSchoolForm')">确 定</el-button>
                <el-button size="medium" @click="addSchoolDialog = false">取 消</el-button>
            </div>
        </el-dialog>

        <!--        分配模板弹窗-->
        <el-dialog title="分配竞赛模板" :visible.sync="templateDialog" width="500px"
                   custom-class="dialog-blue template-dialog">
            <div v-if="templateList.length === 0" style="text-align: center">暂无模板</div>
            <el-scrollbar v-else class="template-list" :native="false">
                <el-checkbox-group v-model="templateListChoose">
                    <el-checkbox :label="temItem.id" v-for="temItem in templateList" :key="temItem.id">
                        {{temItem.tmpl_name_alias}}
                    </el-checkbox>
                </el-checkbox-group>
            </el-scrollbar>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button size="medium" class="btn-blue" @click="sureAssign">确 定</el-button>
                <el-button size="medium" @click="templateDialog = false">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog title="分配理论题库" :visible.sync="theoryDialog" width="500px" custom-class="dialog-blue template-dialog">
            <div v-if="theoryList.length === 0" style="text-align: center">暂无模板</div>
            <!-- <el-scrollbar v-else class="template-list" :native="false">
                <el-checkbox-group v-model="theoryListChoose">
                    <template v-for="theoryItem in theoryList">
                        <el-checkbox :label="subItem.id" v-for="subItem in theoryItem.children" :key="subItem.id">
                            {{subItem.name}}
                        </el-checkbox>
                    </template>
                </el-checkbox-group>
            </el-scrollbar> -->
            <el-cascader-panel :options="theoryList"  v-model="theoryListChoose" :props="{ multiple: true, value: 'id', label: 'name' }" @change="choiceTheory"></el-cascader-panel>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button size="medium" class="btn-blue" @click="submitTheory">确 定</el-button>
                <el-button size="medium" @click="theoryDialog = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {theoryLibSelect, assignTopicCategory, theoryTopicGetSchoolBox} from '@/utils/apis'

    export default {
        data() {
            return {
                schoolList: [],
                schoolPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                // S 创建学校
                schoolOperTitle: '创建学校',
                addSchoolDialog: false,
                addSchoolForm: {
                    id: '',
                    name: '',
                    logo: ''
                },
                schoolRules: {
                    name: [
                        {required: true, message: '请输入学校名称', trigger: 'blur'},
                        {min: 4, max: 30, message: '长度在 4 到 30 个字', trigger: 'blur'}
                    ],
                },
                distinguishBtn: '',
                // 上传头像
                headersParams: {
                    Authorization: localStorage.getItem('token')
                },
                uploadCover: this.$api.collegeUploadLogo,
                imgSrc: null,
                // E 创建学校

                // S 分配模板
                templateDialog: false,
                templateList: [],
                templateListChoose: [],
                checkAssignTemplate: null,
                // E 分配模板

                theoryList: [],
                theoryDialog: false,
                theoryListChoose: [],
                checkAssignTheory: null,
            }
        },
        mounted() {
            this.getSchoolList();
            this.getTemplateList();
            this.getTheoryList();
        },
        activated() {
            this.getTemplateList();
        },
        methods: {
            // 获取分配模板列表
            getTemplateList() {
                this.$http.axiosGet(this.$api.templateList, (res) => {
                    if (res.code === 200) {
                        this.templateList = res.data.data
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getTheoryList() {
                theoryLibSelect().then(res => {
                    if (res.code === 200) {
                        this.theoryList = res.data
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 获取学校列表
            getSchoolList() {
                let params = {
                    page: this.schoolPages.currentPageNum,
                    pageSize: this.schoolPages.eachPageNum,
                    paging: 1,
                }
                this.$http.axiosGetBy(this.$api.college, params, (res) => {
                    if (res.code === 200) {
                        this.schoolList = res.data.data
                        this.schoolPages.total = res.data.total
                        if (this.schoolPages.total !== 0 && this.schoolList.length === 0) {
                            this.schoolPages.currentPageNum--;
                            this.getSchoolList();
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换分页
            schoolCurrentChange(val) {
                this.schoolPages.currentPageNum = val;
                this.getSchoolList();
            },
            // 添加学校
            addSchool() {
                this.addSchoolDialog = true
                this.distinguishBtn = 'add'
                this.schoolOperTitle = '创建学校'
            },
            // 编辑学校
            editBtn(row) {
                this.$http.axiosGetBy(this.$api.college, {id: row.id}, (res) => {
                    if (res.code === 200) {
                        this.addSchoolForm = res.data
                        this.addSchoolForm.logo = res.data.logo_url //没有域名
                        this.imgSrc = res.data.logo_url //没有域名
                        // this.imgSrc = row.log //有域名
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
                this.addSchoolDialog = true
                this.distinguishBtn = 'edit'
                this.schoolOperTitle = '编辑学校'
            },
            // 打开弹窗光标显示
            openAddSchool() {
                this.$refs.schoolInput.focus();
            },
            // start 上传图片
            beforeCoverUpload(file) {
                const isImg = file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg') || file.name.toLowerCase().endsWith('.png');
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isImg) {
                    this.$message.error('上传图片只能是 jpg 或 jpeg 或 png 格式!');
                    return false;
                }
                if (!isLt2M) {
                    this.$message.error('上传LOGO大小不能超过 2MB!');
                }
                return isImg && isLt2M;
            },
            handleCoverSuccess(res, file) {
                if (res.code === 200) {
                    this.imgSrc = res.data.src; // 有域名
                    this.addSchoolForm.logo = res.data.url; //无域名
                    this.$message.success('LOGO上传成功')
                } else {
                    this.$message.error('LOGO上传失败，请稍后再试~')
                }
            },
            // end 上传图片
            // 确定添加学校
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            let formData = new FormData();
                            formData.append('name', this.addSchoolForm.name);
                            formData.append('logo', this.addSchoolForm.logo);
                            this.$http.axiosPost(this.$api.collegeAdd, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.getSchoolList();
                                    this.addSchoolDialog = false;
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        } else if (this.distinguishBtn === 'edit') {
                            let formData = new FormData();
                            formData.append('name', this.addSchoolForm.name);
                            formData.append('logo', this.addSchoolForm.logo);
                            formData.append('id', this.addSchoolForm.id);
                            this.$http.axiosPost(this.$api.collegeUpdate, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.getSchoolList();
                                    this.addSchoolDialog = false;
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 删除
            deleteBtn(id) {
                this.$confirm('是否删除该学校，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosPost(this.$api.collegeDel, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getSchoolList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            // 分配模板
            assignBtn(row) {
                this.templateDialog = true
                this.templateListChoose = []
                this.$http.axiosGetBy(this.$api.college, {id: row.id}, (res) => {
                    if (res.code === 200) {
                        if (res.data.template.length > 0) {
                            let tmp = res.data.template;
                            for (let i = 0; i < tmp.length; i++) {
                                this.templateListChoose.push(tmp[i].template_id)
                            }
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
                this.checkAssignTemplate = row
            },
            // 确定分配模板
            sureAssign() {
                let params = {
                    id: this.checkAssignTemplate.id,
                    // template_id: this.templateListChoose.join(',')
                    // template_id: JSON.stringify(this.templateListChoose)
                }
                if (this.templateListChoose.length > 0) {
                    params.template_id = JSON.stringify(this.templateListChoose)
                } else {
                    params.template_id = JSON.stringify([])
                }
                this.$http.axiosPost(this.$api.collegeTemplate, params, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        this.getSchoolList();
                        this.templateDialog = false
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 关闭添加学校弹窗
            resetForm() {
                this.addSchoolForm = {
                    id: '',
                    name: '',
                    logo: '',
                }
                this.imgSrc = null
            },
            // 分配模板
            assignTheory(row) {
                this.theoryDialog = true
                this.theoryListChoose = []
                theoryTopicGetSchoolBox({school_id: row.id}).then(res => {
                    if (res.code === 200) {
                        this.theoryListChoose = res.data;
                        this.theoryListChoose = this.group(this.theoryListChoose,1);
                        this.theoryList.forEach(e => {
                            e.children.forEach(o => {
                                this.theoryListChoose.map(i => {
                                    if (o.id === i[0]) i.unshift(e.id);
                                })
                            })
                        });
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
                this.checkAssignTheory = row
            },
            // 确定分配模板
            submitTheory() {
                let params = {
                    school_id: this.checkAssignTheory.id,
                }
                if (this.theoryListChoose.length > 0) {
                    // params.topic_category_id_str = this.theoryListChoose.join(',')
                    params.topic_category_id_str = [];
                    this.theoryListChoose.map(o => {
                        params.topic_category_id_str.push(o[o.length-1]);
                    });
                    params.topic_category_id_str = params.topic_category_id_str.join(',');
                } else {
                    params.topic_category_id_str = ''
                }
                assignTopicCategory(params).then(res => {
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        this.getSchoolList();
                        this.theoryDialog = false
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 选择理论题库
            choiceTheory(val) {
                this.theoryListChoose = val;
            },
            group (array, subGroupLength) {
                let index = 0;
                let newArray = [];
                while(index < array.length) {
                    newArray.push(array.slice(index, index += subGroupLength));
                }
                return newArray;
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .education-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .el-breadcrumb {
                line-height: 40px;
            }
        }
    }

    .el-link + .el-link {
        margin-left: 10px;
    }

    .school-logo {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .logo-uploader {
        width: 100px;
        height: 100px;
        border: 1px solid #ddd;

        ::v-deep .el-upload {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .logo-cover {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }

        .logo-uploader-icon {
            color: #ccc;
            font-size: 24px;
        }
    }

    .school-prompt {
        color: #F56C6C;
    }

    ::v-deep .template-dialog {
        .el-dialog__body {
            padding: 30px;
        }
    }

    .template-list {
        margin-top: -10px;

        .el-checkbox-group {
            max-height: 200px;
            min-height: 100px;
        }

        ::v-deep .el-checkbox {
            margin-top: 10px;

            .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                border-color: #1122D8;
                background-color: #1122D8;
            }

            .el-checkbox__input.is-checked + .el-checkbox__label {
                color: #1122D8;
            }
        }
    }
</style>